import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Nav = styled.nav`
  background: rgba(0, 0, 0, 0.8);
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 100px;
`;

const NavLink = styled(Link)`
  color: #fff;
  font-size: 24px;
  text-decoration: none;
  margin-left: 100px;
`;

const NavLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Navbar = () => {
  return (
    <Nav>
      <NavLinks>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/services">Services</NavLink>
      </NavLinks>
    </Nav>
  );
};

export default Navbar;
