import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/NavBar';
import ImageSection from './components/ImageSection';
import GlobalStyle from './GlobalStyle';

const App = () => {
  return (
    <Router>
      <GlobalStyle />
      <Navbar />
      <Routes>
        <Route path="/" element={
            <ImageSection src="img1.jpg" secondSrc="nn1.jpg" />
          } />
        <Route path="/about" element={<div>{/* About Page */}</div>} />
        <Route path="/services" element={<div>{/* Services Page */}</div>} />
      </Routes>
    </Router>
  );
};

export default App;
