import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #333;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 1;
  transition: opacity 1s ease;
`;

const SecondImage = styled.img`
  position: absolute;
  top: 200%;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 0;
  transition: opacity 2s ease;
`;

const TextContainer = styled.div`
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 110vh;
  width: 100%;
`;
const SubtitleWrapper = styled.div`
position: absolute;
bottom: 10px; // Adjust this value to create more or less space between Subtitle3 and the bottom of the TextContainer
`;
const Title = styled.h1`
  color: #ffG;
  font-size: 7rem;
  opacity: 0;
  transition: font-size 0.5s ease, opacity 0.5s ease;
`;

const Subtitle = styled.h6`
  color: #fff;
  font-size: 2.5rem;
  opacity: 0;
  transition: font-size 0.5s ease, opacity 0.5s ease;
`;
const Subtitle2 = styled.i`
  color: #fff;
  font-size: 1.95rem;
  opacity: 0;
  transition: font-size 0.5s ease, opacity 0.5s ease;
  transform: translateY(-50px);
  transition: transform 1s, opacity 1s;
`;

const Subtitle3 = styled.p`
  color: #fff;
  font-size: 1rem;
  opacity: 0;
  margin-top: 7rem;
  transition: font-size 0.5s ease, opacity 0.5s ease;
  transform: translateY(-1000px);
  transition: transform 1s, opacity 1s;
`;
const ImageSection = ({ src, secondSrc }) => {
  const firstImageRef = useRef(null);
  const secondImageRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const subtitleRef2 = useRef(null);
  const subtitleRef3 = useRef(null);

  const handleScroll = () => {
    const scrollPercentage = window.pageYOffset / (document.documentElement.scrollHeight - window.innerHeight);
  
    const titleOpacity = scrollPercentage >= 0.5 ? (scrollPercentage - 0.3) * 4 : 0;
    const subtitleOpacity = scrollPercentage >= 0.5 ? (scrollPercentage - 0.8) * 7 : 0;
    const firstImageOpacity = scrollPercentage > 0.6 ? 1 - (scrollPercentage - 0.6) : 1;
    const secondImageOpacity = scrollPercentage >= 0.9 ? (scrollPercentage - 0.9) * 10 : 0;
  
    if (titleRef.current) {
      titleRef.current.style.opacity = titleOpacity;
    }
    if (subtitleRef.current) {
      subtitleRef.current.style.opacity = subtitleOpacity;
    }
    if (subtitleRef2.current) {
      subtitleRef2.current.style.opacity = scrollPercentage >= 0.5 ? (scrollPercentage - 0.93) * 30 : 0;
      subtitleRef2.current.style.transform = scrollPercentage >= .7 ? 'translateY(0)' : 'translateY(-50px)';
    }
    if (subtitleRef3.current) {
      subtitleRef3.current.style.opacity = scrollPercentage >= 0.9 ? 1 : 0;
      subtitleRef3.current.style.transform = scrollPercentage >= .93 ? 'translateY(0)' : 'translateY(-50px)';
    }
    if (firstImageRef.current) {
      firstImageRef.current.style.opacity = firstImageOpacity;
    }
    if (secondImageRef.current) {
      secondImageRef.current.style.opacity = secondImageOpacity;
    }
  };
  
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <ImageContainer onScroll={handleScroll}>
      <Image ref={firstImageRef} src={src} alt="Nightlife scene" />
      <TextContainer>
        <Title ref={titleRef}>AfterDarkData</Title>
        <Subtitle ref={subtitleRef}>Data Pipelining, Analysis Suite, and AI Platform for NYC Nightclubs</Subtitle>
        <Subtitle2 ref={subtitleRef2}>a more organized and intelligent club management experience</Subtitle2>
        <SubtitleWrapper>
                <Subtitle3 ref={subtitleRef3}>For more info or a consultation please reach out to Jaden@AfterDarkData.ai</Subtitle3>
        </SubtitleWrapper>
        </TextContainer>
    </ImageContainer>
  );
};

export default ImageSection;